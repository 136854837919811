import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2016 = () => (
  <Layout
    title="3M 2016 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2016 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2016 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <Link
                to="../3M-2017-YSC-Finalist-Presentations"
                className="previous"
              >
                <Icon name="arrowleftgray" />
              </Link>
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: '7bc1c64a-904f-4bb0-b5d1-1cc2881a34ff',
                    poster: '2016-finalist-thumbnail-opening-remarks.jpg',
                    title: 'Welcome and Opening Remarks',
                    description:
                      'View 2016 Challenge Final Event Opening Remarks.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '00588156-bcef-4f01-8d1a-3d3d5209f3fd',
                    poster: '2016-finalist-thumbnail-maanasa-mendu.jpg',
                    title: '3M 2016 YSC Winner Maanasa Mendu',
                    description:
                      'View 2016  Challenge Finalist and Winner Maanasa Mendu’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '299bad24-458b-4ee3-9a6c-0ea31be5e20a',
                    poster: '2016-finalist-thumbnail-kaien-yang.jpg',
                    title: '3M 2016 YSC Finalist Kaien Yang',
                    description:
                      'View 2016 Challenge Finalist Kaien Yang’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '9ef1d50d-3c46-4247-b67e-3985b74c9f28',
                    poster: '2016-finalist-thumbnail-mrinali-kesavadas.jpg',
                    title: '3M 2016 YSC Finalist Mrinali Kesavadas',
                    description:
                      'View 2016 Challenge Finalist Mrinali Kesavadas’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '88374bd4-ed19-4b4a-bae1-68541019521b',
                    poster: '2016-finalist-thumbnail-will-paschal.jpg',
                    title: '3M 2016 YSC Finalist Will Paschal',
                    description:
                      'View 2016 Challenge Finalist Kaien Yang’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'dfae97fa-acd1-42c2-8b0a-28211ac9c44a',
                    poster: '2016-finalist-thumbnail-meghna-behari.jpg',
                    title: '3M 2016 YSC Finalist Meghna Behari',
                    description:
                      'View 2016 Challenge Finalist Kaien Yang’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '9b113da4-5a1d-4b20-b02c-de5c7a8f0b98',
                    poster: '2016-finalist-thumbnail-amelia-day.jpg',
                    title: '3M 2016 YSC Finalist Amelia Day',
                    description:
                      'View 2016 Challenge Finalist Kaien Yang’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '5f6bfd6b-5ce0-40ee-a3e0-e0461eb0ad49',
                    poster: '2016-finalist-thumbnail-sofia-tomov.jpg',
                    title: '3M 2016 YSC Finalist Sofia Tomov',
                    description:
                      'View 2016 Challenge Finalist Kaien Yang’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '3233d96c-e613-4db5-a4c2-d8def3ed51bf',
                    poster: '2016-finalist-thumbnail-rohan-wagh.jpg',
                    title: '3M 2016 YSC Finalist Rohan Wagh',
                    description:
                      'View 2016 Challenge Finalist Kaien Yang’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'eac7b7a3-6bd1-4ef2-a77c-7795c6694b08',
                    poster: '2016-finalist-thumbnail-sara-makboul.jpg',
                    title: '3M 2016 YSC Finalist Sara Makboul',
                    description:
                      'View 2016 Challenge Finalist Kaien Yang’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '68927c90-3cc4-48c8-8a53-96ea88ed6341',
                    poster: '2016-finalist-thumbnail-rohit-mital.jpg',
                    title: '3M 2016 YSC Finalist Rohit Mital',
                    description:
                      'View 2016 Challenge Finalist Kaien Yang’s Presentation.',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../3M-2015-YSC-Finalist-Presentations" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <p className="text-26">
              Hear from the <strong>2016 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2016;
